import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"

import * as inlineStyles from "../../components/Layout/inlineStyles"
import styles from "./styles.module.css"
import BookList from "../../components/BookList"
import sanitizeHtml from "sanitize-html"

export default function CreatorTemplate(props) {
  const creator = props.data.wordpressWpCreators
  const books = props.data.allWordpressWpBooks.edges.map(
    bookItem => bookItem.node
  )

  return (
    <Layout>
      <SEO title={`${creator.acf.firstName} ${creator.acf.lastName}`} />
      {creator.acf.portraitImage &&
      creator.acf.portraitImage.localFile &&
      creator.content ? (
        <div
          className={styles.creatorContainer}
          style={inlineStyles.creatorContainer}
        >
          <div className={styles.portrait}>
            <div className={styles.portraitImageContainer}>
              <Img
                className={styles.portraitImage}
                src={creator.acf.portraitImage.localFile.childImageSharp.src}
                fluid={
                  creator.acf.portraitImage.localFile.childImageSharp.fluid
                }
                alt={creator.acf.portraitImage.altText}
              />
              {creator.acf.portraitImage.caption && (
                <div
                  className={styles.imageCaption}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(
                      creator.acf.portraitImage.caption.replace(/\u2028/g, " ")
                    ),
                  }}
                />
              )}
            </div>
          </div>
          <div className={styles.info}>
            <h1>{`${creator.acf.firstName} ${creator.acf.lastName}`}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(creator.content.replace(/\u2028/g, " ")),
              }}
            />
          </div>
        </div>
      ) : (
        <div className={styles.noItem} />
      )}
      <div className="container" style={inlineStyles.container}>
        <div className={styles.moreBooks}>
          <h3 className={styles.moreTitle}>
            {`Titlar av ${creator.acf.firstName} ${creator.acf.lastName}`}
          </h3>
          <BookList books={books} />
        </div>
      </div>
    </Layout>
  )
}

CreatorTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const pageQuery = graphql`
  query($id: String!, $creatorId: Int) {
    wordpressWpCreators(id: { eq: $id }) {
      title
      content
      acf {
        firstName: first_name
        lastName: last_name
        portraitImage: portrait_image {
          altText: alt_text
          caption
          localFile {
            childImageSharp {
              fluid(maxWidth: 460) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allWordpressWpBooks(
      filter: {
        acf: {
          creators: {
            elemMatch: {
              creator: {
                wordpress_id:
                  { eq: $creatorId }
                }
              }
            }
          }
        }
      sort: { order: DESC, fields: acf___editions___release_date }
      ) {
      edges {
        node {
          id
          title
          slug
          acf {
            isbn
            creators {
              creator {
                title: post_title
                acf {
                  firstName: first_name
                  lastName: last_name
                }
              }
            }
            editions {
              releaseDate: release_date
            }
            cover {
              altText: alt_text
              caption
              localFile {
                childImageSharp {
                  fluid(maxWidth: 384) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
